import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { 
  AlertModal,
  Breadcrumb,
  BreadcrumbItem, 
  Button, 
  Link, 
  Modal,
  Typography, 
  Pad,
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { useAppContext } from "contexts/AppContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { Input, SelectMulti } from "stories/forms";
import {
  httpGetCustomerCampaigns,
  httpUpdateCustomerCampaigns,
} from "services/customer_campaigns"
import TableColumnToggle from "components/TableColumnToggle/TableColumnToggle"
import { useNotification } from "stories/components/Notification"
import { getDate, getDateAndTime } from "services/datetime"

const CustomerPriceLists = ({history}) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { customer, getMyCustomer } = useAppContext()
  const { notify } = useNotification();
  const [loadingMcf, setLoadingMcf] = useState(false);
  const [controller, setController] = useState(null);

  useEffect(() => {
    // Fetch data when the component mounts
    // getSalesPriceLists();
    getMyCustomer();

    // Cleanup function to cancel the request if the component unmounts
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, []);

  // Table
  const [loading, setLoading] = useState(0)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "name",
    creators: [],
  })
  const [filterOptions, setFilterOptions] = useState({
    creators: [
      {value: 1, label: "1#Aapinen"},
      {value: 2, label: "2#Bobius"},
    ],
  })
  const isInitialMount = useRef(true);
  const [searchField, setSearchField] = useState()

  useEffect(() => {
    console.log("filters", filters)
  }, [filters])

  const setFilterValue = (name, value) => {
    console.log("setFilterValue", name)
    console.log("setFilterValue", value)
    
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
    
  }

  const getFilterOptions = useCallback((name) => {
    return filterOptions[name] && filterOptions[name].map(item => {
      return item // {value: item, label: item}
    })
    /*
    return values?.suppliers && values?.suppliers.map(supplier => {
      return {value: supplier.id, label: supplier.name}
    })
    */
  }, [filterOptions])
  
  const getSelectedOptions = useCallback((name) => {
    return filters[name] && filters[name].map(item => {
      return {value: item, label: item}
    })
    /*
    return values?.suppliers && values?.suppliers.map(supplier => {
      return {value: supplier.id, label: supplier.name}
    })
    */
  }, [filters])

  const handleCreateOrder = () => {
    history.push(`/dashboard/price_list_orders/order/new`);
  }

  const getCustomerCampaigns = () => {
    console.log("getSalesPriceLists");
    
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    const params = {
      ...filters,
      supplier: filters?.supplier ? filters?.supplier : undefined,
    }

    setLoading(s => s+1)
    httpGetCustomerCampaigns(params, {signal: newController.signal}).then(res => {
      let _rows = [];

      if (customer?.new_campaigns_available === true) {
        _rows = [{
          id: -1,
          state: 9,
        }]
      }
      _rows = [..._rows, ...res?.data]
      setRows(_rows)
    }, error => {
      console.error('httpGetCampaigns error:', error);
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  const updateCustomerCampaigns = () => {
    console.log("updateCustomerCampaigns");
    setLoadingMcf(true)
    httpUpdateCustomerCampaigns().then(res => {
      console.log("updateCustomerCampaigns res", res);
      getMyCustomer();
      getCustomerCampaigns();
    }, error => {
      console.error('updateCustomerCampaigns error:', error);
    }).finally(() => {
      setLoadingMcf(false)
    })

  }
  
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      
    }
    getCustomerCampaigns();
  }, [filters])
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
  }
  const handleSearchChange = ({ target }) => {
    const { value } = target;
    setSearchField(value)
  }
  const handleSearchBlur = () => {
    setFilters(s => ({
      ...s,
      search: searchField,
    }))
  }
  const handleSearchKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      setFilters(s => ({
        ...s,
        search: searchField,
      }))
    }
  }

  const [headers, setHeaders] = useState([
    /*{ label: "", key: "actions" }, */ // empty at the moment
    { label: t("customer_price_lists_header_erp_id","ERP-myyntihinnasto-ID"), key: "campaign_fk", sortable:true, visible: true},
    { label: t("customer_price_lists_header_changes","Toimenpiteet"), key: "actions", sortable:false, visible: true},
    { label: t("customer_price_lists_header_from_date","Voimassa alkaen (pvm + klo)"), key: "from_date", sortable:true, visible: true},
    { label: t("customer_price_lists_header_to_date","Voimassa päättyen (pvm + klo)"), key: "to_date", sortable:true, visible: true},
    { label: t("customer_price_lists_header_name","Myyntihinnaston nimi"), key: "name", sortable:false, visible: true},
    { label: t("customer_price_lists_header_campaign_page","Kampanjasivu"), key: "campaign_page_link", sortable:false, visible: true},
    { label: t("customer_price_lists_header_campaign_code","Kampanjakoodi"), key: "code", sortable:false, visible: true},
    { label: t("customer_price_lists_header_products_count","Päätuotteita (ID)"), key: "product_count", sortable:false, visible: true},
    { label: t("customer_price_lists_header_products_count","Variaatiotuotteita (ID)"), key: "product_variation_count", sortable:false, visible: true},
    
  ]);
  
  const setHeaderValue = (headerKey, key, value) => {
    let headersTemp = headers;
    
    const index = headersTemp && headersTemp.findIndex(item => { return item?.key === headerKey });
    headersTemp[index] = {
      ...headersTemp[index],
      [key]: value,
    }
    setHeaders([
      ...headersTemp
    ])
  }
  
  const handleNew = () => {
    history.push(`/dashboard/sales_orders/price_list/new`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/sales_orders/price_list/edit/${id}/basic`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    let results = [];
    if (rows?.results) {
      results = rows?.results;
    }
    else if (rows) {
      results = rows;
    }

    if (results == null || results.length === 0) {
      return [];
    }
    
    return results.map(row => {
      
      return {
        ...row,
        actions: ( // 1 = No need to update, 2 = Needs to be updated, 3 = Is going to be deleted. To know if there are new campaigns available, there is a boolean new_campaigns_available under the customer obj.
          <>
          {row?.state === 9 ? (
            <Pad 
              color="success"
            >
              (!) {t("Uusi")}
            </Pad>
          ) : null}

          {row?.state === 2 ? (
            <Pad 
              color="warning"
            >
              (!) {t("Päivittynyt")}
            </Pad>
          ) : null}

          {row?.state === 3 ? (
            <Pad 
              color="danger"
            >
              (!) {t("Ei saatavilla")}
            </Pad>
        ) : null} 
          
          </>
        ),
        customer_price_lists_header_changes: (
          <>
            <Pad 
              icon="fa-exclamation-circle"
              color="warning"
            >
              {t("Uusi")}
            </Pad>
          </>
        ),
        name: (
          <>
            <Typography>{row?.name}</Typography>
          </>
        ),
        campaign_page_link: row?.link ? (
          <Link href={row?.link} isExternal={true}>Linkki</Link>
        ) : ( "" ),
        created_at: getDateAndTime(row?.created_at),
        from_date: getDateAndTime(row?.from_date),
        to_date: getDateAndTime(row?.to_date),
        campaign_code: row?.conditions?.code ? row?.conditions?.code : t("sales_price_lists_no_code","Ei"),
      }
    })
  }, [rows])

  return (
    <>
      <Row>
        <Col>
          <Breadcrumb className="mb-0" listClassName="breadcrumb-links">
            <BreadcrumbItem>
                {t("flowm8_purchase_orders","Ostotilaukset")}
            </BreadcrumbItem>
            <BreadcrumbItem>
            {t("flowm8_supplier_price_lists","Toimittajahinnastot")}
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <PageHeader title={t("flowm8_supplier_price_lists","Toimittajahinnastot")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            
            <div className="p-2">
              <TableColumnToggle tableName="SalesPriceLists" userId={myUser?.id} headers={headers} setHeaders={setHeaders} setHeaderValue={setHeaderValue} />
            </div>
            {/*
            <div className="p-2">
              <a className="small" onClick={handleNew}>{t("sales_price_lists_update_campaigns_mcf","Päivitä kampanjat MCF:stä")}</a>
            </div>
            */}
          </div>
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
         
        </Col>
        <Col className="text-right">
          <Button disabled={loadingMcf} color="warning" onClick={updateCustomerCampaigns} style={{padding:"32px"}}>{t("supplier_price_lists_button_update_price_lists","Päivitä hinnastot")}</Button>
          <Button disabled={loadingMcf} color="primary" onClick={handleCreateOrder} style={{padding:"32px"}}>{t("supplier_price_lists_button_create_purchase_order","Luo tilaus")}</Button>
        </Col>
      </Row>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        filters={filters}
        setFilters={setFilters}
        tableStickyHeader={true}
        sortBackend={true}
      />
    
    </>
  );
}

export default CustomerPriceLists;

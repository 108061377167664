import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { 
  AlertModal,
  Breadcrumb,
  BreadcrumbItem, 
  Button, 
  Link, 
  Modal,
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { Input, SelectMulti } from "stories/forms";
import {
  httpGetCampaigns,
  httpGetAllMcfCampaigns,
  httpUpdateAllMcfCampaigns,
  httpGetCampaignCreators,
} from "services/campaigns"

import TableColumnToggle from "components/TableColumnToggle/TableColumnToggle"
import { useNotification } from "stories/components/Notification"
import { getDate, getDateAndTime } from "services/datetime"
import SalesPriceListCreateMcf from "views/dashboard/SalesPriceListCreateMcf/SalesPriceListCreateMcf"

const SalesPriceLists = ({history}) => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { notify } = useNotification();
  const [loadingMcf, setLoadingMcf] = useState(false);
  const [controller, setController] = useState(null);

  useEffect(() => {
    // Fetch data when the component mounts
    // getSalesPriceLists();

    // Cleanup function to cancel the request if the component unmounts
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, []);

  // Table
  const [loading, setLoading] = useState(0)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "id",
    creators: [],
  })
  const [filterOptions, setFilterOptions] = useState({
    creators: [
      {value: 1, label: "1#Aapinen"},
      {value: 2, label: "2#Bobius"},
    ],
  })
  const isInitialMount = useRef(true);
  const [searchField, setSearchField] = useState()

  const getCampaignCreators = () => {
    console.log("httpGetCampaignCreators");
    setLoadingMcf(true)
    httpGetCampaignCreators().then(res => {
      console.log("httpGetCampaignCreators res", res);
      /*
      [
          {
              "id": 131,
              "email": "mikko.uusitalo@kolikon.fi",
              "first_name": "Mikko",
              "last_name": "Uusitalo"
          }
      ]
      */
      
      let _creators = res?.data.map(creator => {
        return {value: creator?.id, label: creator?.first_name + " " + creator?.last_name}
      })

      setFilterOptions(s => ({
        ...s,
        creators: _creators,
      }))
    }, error => {
      console.error('httpGetCampaignCreators error:', error);
    }).finally(() => {
      setLoadingMcf(false)
    })
  }

  useEffect(() => {
    getCampaignCreators()
  }, [])

  const getSalesPriceLists = () => {
    console.log("getSalesPriceLists");
    
    if (controller) {
      controller.abort();
    }
    const newController = new AbortController();
    setController(newController);

    const params = {
      ...filters,
      supplier: filters?.supplier ? filters?.supplier : undefined,
    }

    setLoading(s => s+1)
    httpGetCampaigns(params, {signal: newController.signal}).then(res => {
      setRows(res?.data)
    }, error => {
      console.error('httpGetCampaigns error:', error);
    }).finally(() => {
      setLoading(s => s-1)
    })
  }

  const getAllMcfCampaigns = () => {
    console.log("httpGetAllMcfCampaigns");
    setLoadingMcf(true)
    httpGetAllMcfCampaigns().then(res => {
      console.log("httpGetAllMcfCampaigns res", res);
      getSalesPriceLists();
    }, error => {
      console.error('httpGetAllMcfCampaigns error:', error);
    }).finally(() => {
      setLoadingMcf(false)
    })
  }

  const getUpdateMcfCampaigns = () => {
    console.log("httpUpdateAllMcfCampaigns");
    setLoadingMcf(true)
    httpUpdateAllMcfCampaigns().then(res => {
      console.log("httpUpdateAllMcfCampaigns res", res);
      getSalesPriceLists();
    }, error => {
      console.error('httpUpdateAllMcfCampaigns error:', error);
    }).finally(() => {
      setLoadingMcf(false)
    })
  }

  

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      
    }
    getSalesPriceLists();
  }, [filters])
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value,
    }))
  }

  const preHeader = useCallback({
    "creator": {
      content: (
        <>
        {/*
        <SelectMulti label={t("sales_price_lists_filter_creator","Hinnaston laatija")} placeholder={t("Ei rajausta")} name="creators" values={filters} setFieldValue={setFilterValue} defaultValues={getSelectedOptions("creators")} options={getFilterOptions("creators")} />
        */}
        <Input label={t("sales_price_lists_filter_creator","Hinnaston laatija")} type="select" name="creator_fk" onChange={handleFilterChange} value={filters?.creator_fk}>
          <option value="">{t("Ei rajausta")}</option>
          { filterOptions["creators"] && filterOptions["creators"].map(item => {
            return (
              <option key={item?.value} value={item?.value}>{item?.label}</option>
            )
          })}
        </Input>
        </>
        )
    },
    "published": {
      content: (
        <Input label={t("sales_price_lists_filter_published","Aktiivisuustila")} type="select" name="published" onChange={handleFilterChange} value={filters?.published}>
          <option value="">{t("Ei rajausta")}</option>
          <option value="true">{t("sales_price_lists_published","Julkaistu")}</option>
          <option value="false">{t("sales_price_lists_not_published","Ei julkaistu")}</option>
        </Input>
      )
    },
  }, [filters, filterOptions])

  const [headers, setHeaders] = useState([
    /*{ label: "", key: "actions" }, */ // empty at the moment
    { label: t("sales_price_lists_header_erp_id","ERP-myyntihinnasto-ID"), key: "id", sortable:true, sortingKey: "id", visible: true},
    { label: t("sales_price_lists_header_mcf_campaign_id","MCF-kampanja-ID"), key: "id_mcf", sortable:true, sortingKey: "id_mcf", visible: true},
    { label: t("sales_price_lists_header_created","Luotu (pvm)"), key: "created_at", sortable:true, sortingKey: "created_at", visible: true},
    { label: t("sales_price_lists_header_creator","Laatinut"), key: "creator", sortable:true, sortingKey: "creator_fk", visible: true},
    { label: t("sales_price_lists_header_active","Aktiivinen"), key: "published", visible: true},
    { label: t("sales_price_lists_header_valid_start","Voimassa alkaen (pvm+klo)"), key: "valid_start", sortable:false, visible: true},
    { label: t("sales_price_lists_header_valid_end","Voimassa päättyen (pvm+klo)"), key: "valid_end", sortable:false, visible: true},
    { label: t("sales_price_lists_header_name","Myyntihinnaston nimi"), key: "name", sortable:false, visible: true},
    { label: t("sales_price_lists_header_campaign_name","Kampanjasivut"), key: "link", sortable:false, visible: true},
    { label: t("sales_price_lists_header_campaign_code","Kampanjakoodi"), key: "campaign_code", sortable:false, visible: true},
    { label: t("sales_price_lists_header_mcf_version_id","MCF-kauppaversio-ID"), key: "visibilities", sortable:false, visible: true},
    { label: t("sales_price_lists_header_mcf_customer_group_id","MCF-asiakasryhmä-ID"), key: "customer_groups", sortable:false, visible: true},
    { label: t("sales_price_lists_header_products_count","Päätuotteita (ID)"), key: "product_count", sortable:false, visible: true},
    { label: t("sales_price_lists_header_variations_count","Variaatiotuotteita (ID)"), key: "product_variation_count", sortable:false, visible: true},
    { label: t("sales_price_lists_header_discount_rows_count","Alennusrivejä (kpl)"), key: "campaign_price_count", sortable:false, visible: true},
    { label: t("sales_price_lists_header_unpriced_count","Hinnoittelematta (kpl)"), key: "campaign_price_without_price_count", sortable:false, visible: true},
    // { label: t("sales_price_lists_header_erp_customer_group_id","ERP-asiakasryhmä-ID"), key: "erp_customer_group_id", sortable:false, visible: true},
    
  ]);
  
  const setHeaderValue = (headerKey, key, value) => {
    let headersTemp = headers;
    
    const index = headersTemp && headersTemp.findIndex(item => { return item?.key === headerKey });
    headersTemp[index] = {
      ...headersTemp[index],
      [key]: value,
    }
    setHeaders([
      ...headersTemp
    ])
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    let results = [];
    if (rows?.results) {
      results = rows?.results;
    }
    else if (rows) {
      results = rows;
    }

    if (results == null || results.length === 0) {
      return [];
    }
    
    return results.map(row => {
      
      return {
        ...row,
        id: (
          <Typography>
            <Link to={`/dashboard/sales_orders/price_list_edit/${row?.id}/basic/`}>
            {row?.id}
            </Link>
          </Typography>
        ),
        name: (
          <>
            <Typography>{row?.translations[0]?.name}</Typography>
          </>
        ),
        creator: row?.creator_fk?.first_name ? row?.creator_fk?.first_name + " " + row?.creator_fk?.last_name : null,
        created_at: getDateAndTime(row?.created_at),
        published: row?.published ? t("sales_price_lists_published","Kyllä") : t("sales_price_lists_not_published","Ei"),
        valid_start: getDateAndTime(row?.conditions?.time?.from_date),
        valid_end: getDateAndTime(row?.conditions?.time?.to_date),
        link: row?.page?.link ? (
          <Link href={row?.page?.link} isExternal={true}>Linkki</Link>
        ) : (""),
        campaign_code: row?.conditions?.code ? row?.conditions?.code : t("sales_price_lists_no_code","Ei"),
        visibilities:  row?.visibilities && row?.visibilities.map(visibility => {
          return visibility?.version_id
        }).join(";"),
        customer_groups: row?.conditions?.customers?.customer_groups && row?.conditions?.customers?.customer_groups.map(customer_group => {
          return customer_group?.id_mcf
        }).join(";"),
      }
    })
  }, [rows])

  // Modal create new price list BEGIN
  const [modalNewPriceListOpen, setModalNewPriceListOpen] = useState(false)

  const openModalNewPriceList = () => {
    setModalNewPriceListOpen(true);
  }

  const toggleModalNewPriceList = () => {
    setModalNewPriceListOpen(s => !s);
  }

  const resolveModalNewPriceList = () => {
    setModalNewPriceListOpen(false);
  }

  const rejectModalNewPriceList = () => {
    setModalNewPriceListOpen(false);
    
  }
  // Modal create new price list END


  return (
    <>
      <Row>
        <Col>
          <Breadcrumb className="mb-0" listClassName="breadcrumb-links">
            <BreadcrumbItem>
                {t("flowm8_Sales_orders","Myyntitilaukset")}
            </BreadcrumbItem>
            <BreadcrumbItem>
            {t("flowm8_Sales_price_lists","Myyntihinnastot")}
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <PageHeader title={t("sales_price_lists_title","Myyntihinnastot")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            
            <div className="p-2">
              <TableColumnToggle tableName="SalesPriceLists" userId={myUser?.id} headers={headers} setHeaders={setHeaders} setHeaderValue={setHeaderValue} />
            </div>
            <div className="p-2">
              <a className="small" onClick={getUpdateMcfCampaigns}>{t("sales_price_lists_update_campaigns_mcf","Päivitä kampanjat MCF:stä")}</a>
            </div>
          </div>
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
         
        </Col>
        <Col className="text-right">
          <Button disabled={loadingMcf} color="warning" onClick={openModalNewPriceList}>{t("sales_price_lists_button_create_mcf_campaign_price_list","Lisää MCF-kampanjahinnasto")}</Button>
          {/*
          <Button color="success" onClick={handleNew}>{t("sales_price_lists_button_create_sales_price_list","Lisää uusi myyntihinnasto")}</Button>
          */}
        </Col>
      </Row>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        preHeader={preHeader}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        filters={filters}
        setFilters={setFilters}
        tableStickyHeader={true}
      />

      <Modal
        size="modal-sm"
        /*title={t("sales_price_lists_modal_new_price_list_title", "Lisää uusi myyntihinnasto")}*/
        isOpen={modalNewPriceListOpen}
        toggleModal={toggleModalNewPriceList}
        closeModal={rejectModalNewPriceList}
      >
        <SalesPriceListCreateMcf
          history={history}
          handleResolve={resolveModalNewPriceList}
          handleReject={rejectModalNewPriceList}
        />
      </Modal>

    </>
  );
}

export default SalesPriceLists;
